.spinner {
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width: 100%;
    height: 100%;
    background: #bbb7b7;
    opacity: 0.3;
    z-index: 1100;

}

.spinner > div {
    left: 50%;
    position: fixed;
    text-align: center;
    top: 40%;

}
