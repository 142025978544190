.navbar-light .navbar-nav .nav-link {
  color: #008a00;
  font-weight: bold;
  font-size: medium;
  font-family: TD Graphik Medium;
  border: 0;
  padding: 1rem 0.5rem 1rem 0;
  margin: 0 1rem;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.navbar-nav>.dropdown {
  border: 0!important;
  margin-bottom:0;
  border-radius:0!important;

}

.active-dropdown-button {
  box-shadow: 0px 0px 0px 13px #FFFFFF;
  background-color: #FFFFFF;
  color: #008a00;
  font-size: medium;
  font-weight: bold;
}

.dropdown-custom{
  margin-left:-15px !important;
}

.navbar-nav>.dropdown:hover {
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
  background-color:white;
  /*z-index: 10002!important;*/
}

.navbar {
  padding: 0 10px;
}

.dropdown-menu {
  border: 0 !important;
  border-radius: 0;
  font-size: medium;
  min-width: 95px;
  max-width: 233px;
  background-color: white;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
  clip-path: inset(-15px -15px -15px -15px);
  /*margin-top:1px!important;*/
  /*z-index: -100!important;*/
}

.dropdown-item {
  font-weight:bold;
}

#mySidenav a {
  position: fixed;
  left: -80px;
  transition: 0.3s;
  margin-top: 15px;
  padding: 15px;
  padding-right: 5px;
  width: 100px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  border-radius: 0px 5px 5px 0px;
  text-align: right;
}

#mySidenav a:hover {
  left: 0;
  color: white;
}

#sidebar-filter {
  z-index: 10000;
  background-color: #008a00;
}

.red-cell {
  color: red;
}

.green-cell {
  color: green;
}

.orange-cell {
  color: orange;
}

.red-cell a:hover  {
  color: red !important;
}

#uploadCard {
  cursor:pointer;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.icon-rotate {
  transform: rotateZ(180deg);
}

.icon-move {
  transform: rotateZ(90deg);
  color: rgba(0,0,0,.30);
}

.dragMe {
  cursor: move;
}

.resizeMeHorizontally {
  cursor: col-resize;
}

.green-stripe {
  border: 5px solid #008A00;
  border-bottom: 0;
  border-top:0;
  border-right:0;
  margin-bottom: 5px;
  left:0;
  background: #f3f3f3;
  padding-left: 25px !important;
}

.td-btn-red{
  background: #AE1100;
  color: white;
  transition: all 0.4s;
}

.form-rounded{
  border-radius: 20em;
}
.overlay {
  position:absolute;
  color:#038203 !important;
  z-index:12;
  top:0;
  left:0;
  background:#fff;
  height:100%;
  text-align:left;
  font-size: large;
  font-weight: bold;
  transition: all;
  width:90%;
}

.overlay a {
  color:black !important;

}

#overlay {
  position: fixed;
  color:#038203;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
  transition: all
}

.custom-dialog {
  position:fixed;
  z-index:20;
  bottom:-3%;
  left:0;
  right:0;
  width: 100%;
  max-width: 100%;
  max-height:100%;
  transition: all
}

.filter-input-label{
  font-size: small;
  font-weight: normal;
  margin-bottom: 0;
  color:black;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-self: center;
}

.nav-right{
  position:absolute;
  right:2em;
}

.large-font{
  font-size: 1.23rem;
}

.badge-outline {
  color: #000000;
  background-color: transparent;
}

.badge-button{
  color: #f1f0f0;
  background-color: #008A00;
}

.badge {
  border-radius: 0.375rem;
}

.badge-button:hover{
  cursor: pointer;
}

.badge-button, .badge-outline{
  border: 1px solid #008A00;
  padding-Top: 0.5em;
  font-weight: normal;
  margin: 5px 10px 5px 0px;
}

.bell-icon-tab{
  margin-top: 0.8em;
}

.bell-icon-mob{
  margin-top: 1.5em;
}

.fullscreen-dialog {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin:0px;
  padding:0px;
}

.mobile-fullscreen-dialog {
  margin:0px;
}

.modal-content {
  height: auto;
  min-height: 100%;
}

.react-pdf__Page{
  background: #53565b;
  text-align: center;
  display:flex;
  justify-content:center;
  padding-Top:50px;
  padding-Bottom:50px;
}

.linkColor, .linkColor:visited{
  color: #fdfdfe;
  text-decoration: none;
}

.transform-component-module_content__2jYgh{
  display: block!important;
  width: auto!important;
  text-align: center;
}

.transform-component-module_wrapper__1_Fgj{
  width: auto!important;
}

/* Reduce ag grid cell height*/
.ag-theme-alpine .ag-cell{
  line-height:30px;
}

/* Reduce ag grid entire row height*/
.ag-theme-alpine .ag-row{
  height:32px;
}

div[col-id="instruction"] .ag-react-container{
    line-height: 22px;
}
div[col-id="prevComments"] .ag-react-container{
  line-height: 22px;
}

.toastStyle{
  position: fixed;
  right:2em;
  bottom: 2em;
  color: green ;
  width:40px;
}
.contactStyle{
  position: fixed;
  right:2em;
  bottom: 2em;
  color: green ;
}

.contactusIcon{
  background:green;
  color:white;
  border-radius: 5em;
  padding: .2em;
}

/* Reduce agGrid default pagination margin so it doesn't overlap in mobile screens */
.ag-theme-alpine .ag-paging-panel > * {
  margin: 0 10px;
}

/* Override React-slick Left-Right arrows color*/
.slick-prev:before, .slick-next:before{
  color: green;
}

/* Override ActionModal default left padding */
div.fade.modal.show{
  padding-left:0px!important;
}

.black-font{
  color:black !important;
}

.query-action-dialog { margin:0% -1% !important; padding:0% !important; width:101% !important; max-width: none !important; top:70%; }
.query-input-dialog { margin:0% 0% !important; padding:0% !important; width:100% !important; max-width: none !important; top:80%; }


.ag-theme-balham .ag-row-selected {
  background-color: #4abbff;
}

.ag-theme-balham .ag-ltr .ag-cell-focus {
  border: 1px solid red;
}
.modal-open { overflow-y: auto; }

.nav-mobile-dropdown{
  background: none;
  border: none;
  color: black;
  font-weight:bold;
  font-size:medium;
  font-family: TD Graphik SemiLight;
  padding-left:1em;
}

.nav-mobile-submenu{
  padding-top:0.3em;
  padding-bottom:0.3em;
  padding-left:2.5em;
  margin:0;
}

/*Add the classes to rotate the menu arrows*/
.accordion-arrow-rotate {
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.half-screen-width{
  width:50%;
}

.icon-green{
  color:#008A00
}

.stick-to-bottom{
  position:fixed;
  bottom:2.2em;
}

select.form-control {
  /*background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='currentColor'  stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");*/
  background-repeat: no-repeat;
  background-size: 16px 12px;
  background-position: right .75rem center;
  appearance: none;
  /* padding-right: 34px; */
}

/*
select.form-control:disabled {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='gray'  stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");

}
*/

input[type=date]::-webkit-calendar-picker-indicator{
  margin-right:-5px;
}

.unset-custom-arrows {
  background-image: none !important;
  appearance: revert !important;
}


.navbar-nav>.dropdown>.dropdown-toggle::after{
  border-top:none;
}
.dropdown-toggle{
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke="green" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
  background-repeat: no-repeat;
  background-size: 9px;
  background-position: right center;
}

.dropdown-toggle:hover{
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" stroke="green" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/></svg>');
}

.dropdown:hover>.dropdown-toggle{
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" stroke="green" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/></svg>');
}

.dropdownstyle>button:focus::before{
  opacity: 0 !important;
}

.dropdownstyle>button:focus{
  padding:0.8em;
  margin-bottom:0;
}

.modal-header .close{
  margin:3px 0 0;
  padding-bottom: 0;
  padding-top: 0;
}

.custom-toast{
      position:fixed;
      bottom:20px;
      text-align:center;
      font-size:1em;
      min-width:73%
}

.custom-toast-container{
  display:flex;
  justify-content:center;
  position:absolute;
  bottom:40px;
  left:30px;
  right:30px;
  z-index:9999;
}

.accountTypeDropdown{
  border: 0;
  border-bottom: 2px solid green;
  padding-bottom: 0;
  margin-bottom: 0;
}
.ag-pinned-left-cols-container {

  height:100% !important;
}
.ag-pinned-right-cols-container {
  height:100% !important;
}


div.ag-theme-alpine div.ag-row {
  font-size: 12px !important;
}

.ag-header-cell-text {
  font-size: 12px !important;

}
.ag-header-cell{
font-size: 12px !important;

}
.custom-tooltip {
  position: fixed;
  z-index: 1000;
  height:550px;
  width:550px;
  top:35%;
  left:5%;
  background:#f8f8f8;
  border: 1px solid cornflowerblue;

}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}



.grey-out{
  background-color: #f8f8f8 !important;
  color: lightgray !important;
}

.td-select::after {
  border-bottom-width: 1px;
 border-right-width: 1px;
  border-color: #616161;
}

.td-select::after {
  margin-top: 1.2em;
}

.folder-dropDown .td-select::after{
  margin-top: 1.2em;
  opacity: 0.1;
}

#td-select-error::after {
  margin-top: -0.3rem;
}

.invalid-feedback b {
  font-size: 13px;
  font-family: "TD Graphik Medium",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.bi-exclamation-circle {
  vertical-align: -0.07em;
  margin-right: 0.5rem;
  -webkit-text-stroke: 0.5px #ae1100;
}

.custom-dialog .green-stripe {
  padding-left: 95px !important;
}

.fullscreen-dialog .green-stripe {
  padding-left: 5px !important;
}

#basic-grid .justify-content-end {
  justify-content: left !important;
}

.disable{
  pointer-events: none;
  color: #ccc;
}

.dtcWidth {
  width: 300px;
}


.justify-content-ct {
  justify-content:center;
}

.stick-to-botom{
  position:relative;
  bottom:0.1em;
  padding-right: 4rem;
}

.rename{
  white-space:pre;
  text-align: left;
  display: inline-block;
  max-width: 180px;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rn {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space:pre;
  display: inline-block;
}

.scrl {
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.modal.show .modal-dialog {
  max-width: 71em;
}


.topBorder.firstColumn {
   margin-right: 2.4em;
}

.lastColumn{
  max-width: 70%;
}

.secondColumn{
  max-width: 80%;
}
.clr{
  background-color: #F7E8E9;
  color:#AE1100;
}

.modal-content .modal-header .close {
padding-left: 0;
padding-right: 14px;
}

.mobileScreen {
  padding-right: 3px;
  padding-left: 0;
}

.custom-table{
  border: 1px solid #dee2e6 !important;

}
.custom-table td:first-child{
  width:20%;
  font-weight: bold;
}
.custom-table td{
  width:40%;
  padding: 5px;
  padding-left: 10px;
}

.login-app-footer {
  font-size: 11px;
}

.login-app-footer a {
  font-size: 11px;
}


.checkbox-disabled .ag-selection-checkbox input[type="checkbox"] {
  pointer-events: none;
  opacity: 4;
  cursor: not-allowed;
  visibility: visible;
  background-color: #F3F3F3;
  border: 2px solid #CCCCCC;
}

.modal-container {
  overflow-y: scroll;
  max-height: 450px;
}

.modal-container::-webkit-scrollbar {
  display: block;
  width: 5px;
}
.modal-container::-webkit-scrollbar-track {
  background: transparent;
}

.modal-container::-webkit-scrollbar-thumb {
  border-right: none;
  border-left: none;
}



/*Report Panel pdf print layout*/
@media print {
  body {
    margin: 0 ;
  }

  .printLayoutPage {
    page-break-after: always;
    break-inside: avoid;
  }

  @page {
    size: auto;
    margin: 0mm;
  }
}

.entityParent{
  position:relative;
}

.entityDropdown{
  z-index: -1;
}

.entityTextbox{
}

.css-1fdsijx-ValueContainer{
font-family : "TD Graphik SemiLight" !important;
  color:#1c1c1c !important;
}
#react-select-3-placeholder{
  font-family : "TD Graphik SemiLight" !important;
  color:#1c1c1c !important;
}

.error-text{
  color:#ae1100
}

.css-1jqq78o-placeholder, .css-1jqq78o-placeholder::placeholder,.css-1nmdiq5-menu {
  color: black !important;
  opacity: 1 !important;
  font-weight: bold !important;
  font-family :"TD Graphik Medium",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.css-1jqq78o-placeholder::-ms-input-placeholder { /* Edge 12-18 */
  color: black !important;
}

.scroll-to-top,
.scroll-to-bottom
{
  position: fixed;
  opacity: 0;
  transition: opacity 2s ease-in-out,
  visibility 2s ease-in-out;
  background-color: #FFFFFF;
  border:1px solid #038203;
  box-shadow: 0 0 10px 0 #B5B5B5;
  color: #038203;
  font-family: 'TD Graphik Medium', Arial, sans, sans-serif;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  border-radius: 25px;
  visibility: hidden;
  height: 26px;
  z-index: 1000;
  right: 32px;
}

.scroll-to-top {
  bottom: 30px;
  width: 60px;
}

.scroll-to-bottom {
  width: 78px;
  top: 197px;
}


.scroll-to-top.show,
.scroll-to-bottom.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 2s ease-in-out,
  visibility 0s;
}

/*Top and Bottom scroll button media queries*/
@media (min-width: 768px) and (max-width: 819px) {
  .scroll-to-top {
    bottom: 16px;
    right: 82px
  }

  .scroll-to-bottom {
    top: 82px;
    right: 82px
  }
}

@media (min-width: 820px) and (max-width: 912px) {
  .scroll-to-top {
    bottom: 18px;
    right: 120px
  }

  .scroll-to-bottom {
    top: 82px;
    right: 120px
  }
}

@media (max-width: 760px) {
  .scroll-to-top {
    bottom: 18px;
    right: 50px;
    z-index: 1;
  }

  .scroll-to-bottom {
    top: 150px;
    right: 50px;
    z-index: 1;
  }
}

@media (min-width: 1023px) and (max-width: 1024px){
  .scroll-to-top {
    right: 82px;
  }

  .scroll-to-bottom {
    top: 150px;
    right: 82px;
  }
}

.error{
  color:#AE1100;
}

.textAlignRight{
  text-align: right;
}

.break-grid .ag-theme-alpine .ag-header-group-cell, .break-grid .ag-cell, .break-grid , .break-grid .ag-header-cell {
  padding-left:  5px !important;
  padding-right: 5px !important;

}

.break-grid .ag-theme-alpine {
  --ag-cell-horizontal-padding: 0;
}
.break-grid .ag-floating-filter-button{
  margin-left:1px !important;
}
